import { Controller } from "stimulus"
import * as Modal from "../src/javascripts/modal.js"

export default class extends Controller {

  static targets = ["companyData", "privateData", "password_current", "password_new", "password_confirm", "error_password_length", "error_password_match"]

  initialize() {
    if(this.element.getAttribute("data-page")) {
      this.page = this.element.getAttribute("data-page");
    }

    this.locale = this.element.getAttribute("data-locale");
    if(!this.locale) {
      this.locale = document.querySelector("meta[name='locale']").getAttribute('content');
    }
  }

  connect() {
    if(window.location.pathname.includes('profile')) {
      //this.loadOrders();
    }
  }

  checkForm() {
    let error = false;

    let email1 = document.querySelector("#customer_email");
    let email2 = document.querySelector("#customer_email_confirmation");

    let password1 = document.querySelector("#customer_password");
    let password2 = document.querySelector("#customer_password_confirmation");

    if(email1 && email2) {
      if(email1.value != email2.value) {
        email1.classList.add('is-danger');
        email2.classList.add('is-danger');
        document.querySelector("#email-match").classList.remove('is-hidden');
        error = true;
      } else {
        email1.classList.remove('is-danger');
        email2.classList.remove('is-danger');
        document.querySelector("#email-match").classList.add('is-hidden');
      }
    }

    if(password1.value.trim() != password2.value.trim()) {
      password1.classList.add('is-danger');
      password2.classList.add('is-danger');
      document.querySelector("#password-match").classList.remove('is-hidden');
      error = true;
    } else {
      password1.classList.remove('is-danger');
      password2.classList.remove('is-danger');
      document.querySelector("#password-match").classList.add('is-hidden');
    }

    if(password1.value.trim().length < 6) {
      password1.classList.add('is-danger');
      password2.classList.add('is-danger');
      document.querySelector("#password-length").classList.remove('is-hidden');
      error = true;
    } else {
      password1.classList.remove('is-danger');
      password2.classList.remove('is-danger');
      document.querySelector("#password-length").classList.add('is-hidden');
    }

    if(error) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  addAddress() {
    let box = document.querySelector("#editAddress");
    box.classList.add('is-active');

    let street = box.querySelector('#address_street');
    street.focus();

    box.querySelector('.button.is-danger').classList.add('is-hidden');
  }

  editAddress() {
    let addressId = event.target.dataset.addressId;
    let address = event.target.dataset.address;
    let box = document.querySelector("#editAddress");
    box.classList.add('is-active');

    let id = box.querySelector('input[name="id"]');
    id.value = addressId;

    box.querySelector('.button.is-danger').classList.remove('is-hidden');

    let textarea = box.querySelector('textarea');

    let name = box.querySelector('#address_name');
    let street = box.querySelector('#address_street');
    let zip = box.querySelector('#address_zip');
    let location = box.querySelector('#address_location');
    let state = box.querySelector('#address_state');
    let country = box.querySelector('#address_country');

    name.value = event.target.dataset.name;
    street.value = event.target.dataset.street;
    zip.value = event.target.dataset.zip;
    location.value = event.target.dataset.location;
    if(state) {
      state.value = event.target.dataset.state;
    }
    country.value = event.target.dataset.country;

    street.focus();
  }

  deleteAddress() {
    var customersController = this;
    let box = document.querySelector("#editAddress");
    let addressId = box.querySelector('input[name="id"]').value;
    console.log(addressId);

    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function(){
      if (xmlhttp.readyState == 4 && xmlhttp.status == 202) {
        let div = document.querySelector('#addresses');
        let addr = div.querySelector(`div[data-address-id='${addressId}']`);
        main.fadeOut(addr, 0);
        customersController.closeModal();
      }
    }
    xmlhttp.open("DELETE", `/customers/address/${addressId}/delete`, true);
    //xmlhttp.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content);
    xmlhttp.send();
  }

  closeModal() {
    let modal = document.querySelector(".modal");

    let street = modal.querySelector('#address_street');
    let zip = modal.querySelector('#address_zip');
    let location = modal.querySelector('#address_location');
    let state = modal.querySelector('#address_state');
    let country = modal.querySelector('#address_country');

    street.value = '';
    zip.value = '';
    location.value = '';
    if(state) {
      state.value = '';
    }
    country.value = '';

    document.querySelector("#editAddress").classList.remove('is-active');
  }

  loadOrders() {
    Rails.ajax({
      url: "/en/orders",
      type: "get",
      data: "",
      success: function(data) {
        var tbody = document.querySelector("#orders");
        data.forEach( (order) => {

          let paid_icon = '<i class="fas fa-times-circle"></i>';
          if(order['paid']) {
            paid_icon = '<i class="fas fa-check-circle has-text-success"></i>';
          } else if(order['state'] == '04_delivered' || order['state'] == '05_completed') {
            paid_icon = '<i class="fas fa-check-circle has-text-danger"></i>';
          }

          let items = '';

          tbody.innerHTML += `<tr>
            <td>${order['orderref']}</td>
            <td>${order['state_label']}</td>
            <td>${paid_icon}</td>
            <td>${items}</td>
            <td>${order['total_with_vat']}</td>
          `
        })
      },
      error: function(data) {}
    })
  }

  toggleCustomerType() {
    if(event.target.value) {
      if(event.target.value == 'company') {
        this.privateDataTarget.classList.add('is-hidden');
        this.companyDataTarget.classList.remove('is-hidden');
      } else {
        this.companyDataTarget.classList.add('is-hidden');
        this.privateDataTarget.classList.remove('is-hidden');
      }
    }
  }

  checkPassword() {
    if(this.password_newTarget.value != this.password_confirmTarget.value) {
      this.error_password_matchTargets.forEach((p) => {
        p.classList.remove('is-hidden');
      })
    } else {
      this.error_password_matchTargets.forEach((p) => {
        p.classList.add('is-hidden');
      })
    }

    if(this.password_newTarget.value.length < 6) {
      this.error_password_lengthTargets.forEach((p) => {
        p.classList.remove('is-hidden');
      })
    } else {
      this.error_password_lengthTargets.forEach((p) => {
        p.classList.add('is-hidden');
      })
    }
  }

  passwordForgotten() {
    let customersController = this;
    let title = I18n.t('customer.password_reset');
    let content = `
      ${I18n.t('customer.confirmations.password_reset')}
      <div class="field">
        <div class="control pt-4 pb-4 pr-1">
          <input class="input" type="email" id="email" placeholder="${I18n.t('customer.email')}">
        </div>
      </div>
    `;

    let modal = Modal.createModal(title, content, [{label: I18n.t('btn.cancel'), event: 'cancel', classes: ''}, {label: I18n.t('btn.send'), event: 'success', classes: 'is-primary'}]);
    modal.openModal();

    modal.addEventListener('success', (event) => {
      let email = event.detail.email;
      if(email && email != '') {
        const formData = new FormData();
        formData.append("email", email);

        Rails.ajax({
          url: `/${customersController.locale}/customers/password/reset`,
          type: "POST",
          data: formData,
          success: function(data) {
            let title = I18n.t('customer.password_reset');
            let content = I18n.t('customer.confirmations.password_reset_mail_sent');
            modal = Modal.createModal(title, content, [{label: I18n.t('btn.close'), event: 'success', classes: ''}]);
            modal.openModal();
          },
          error: function(data) {}
        });
      }
    })
  }
}
