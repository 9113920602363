require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels");
require("i18n-iso-countries");
require("pinch-zoom-js");
require("tinygesture");

countries.registerLocale(require("../src/javascripts/i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("../src/javascripts/i18n-iso-countries/langs/de.json"));

import Rails from '@rails/ujs';
import "../src/javascripts/i18n.js.erb";

const images = require.context('../images', true)
//const imagePath = (name) => images(name, true)

import countries from 'i18n-iso-countries';

import "controllers"

import Modal from "../src/javascripts/modal.js";
import * as main from "../src/javascripts/main.js.erb";

import bulmaCarousel from "bulma-carousel/dist/js/bulma-carousel.min.js";

window.Rails = Rails;
window.countries = countries;
window.main = main;
window.I18n = I18n;
window.Modal = Modal;
window.bulmaCarousel = bulmaCarousel;
